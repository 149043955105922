import Airtable from "airtable";

class Api {
  constructor() {
    this.currentpost = {};
    Airtable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: "keyL4clkiuEPn3Ccu"
    });
    this.base = Airtable.base("appBgRnoEdKjKyvZt");
  }
  getRecords = () => {
    let _posts = [];
    return new Promise((resolve, reject) => {
      this.base("Posts")
        .select({
          maxRecords: 10,
          view: "List"
        })
        .eachPage(
          (records, fetchNextPage) => {
            records.forEach(async record => {
              if (record.get("Title")) {
                let _post = {
                  title: record.get("Title"),
                  date: record.get("Date"),
                  authors: record.get("Authors"),
                  slug: record.get("Slug"),
                  tags: record.get("Tags")
                };
                _posts.push(_post);
              }
            });
            // fetchNextPage();
            resolve(_posts);
          },
          err => {
            if (err) {
              reject(err);
              return;
            }
          }
        );
    });
  };
  getPost = slug => {
    return new Promise((resolve, reject) => {
      this.base("Posts")
        .select({
          filterByFormula: `{Slug} = '${slug}'`
        })
        .firstPage((e, r) => {
          if (e) {
            return reject("darn not found");
          }
          r.forEach(async record => {
            let post = {
              title: record.get("Title"),
              date: record.get("Date"),
              authors: record.get("Authors"),
              slug: record.get("Slug"),
              tags: record.get("Tags"),
              content: record.get("Content")
            };
            resolve(post);
          });
        });
    });
  };
}
export default new Api();
// export const getRecords = () => {
//   let _posts = [];
//   return new Promise((resolve, reject) => {
//     base("Posts")
//       .select({
//         maxRecords: 10,
//         view: "List"
//       })
//       .eachPage(
//         (records, fetchNextPage) => {
//           records.forEach(async record => {
//             if (record.get("Title")) {
//               let _post = {
//                 title: record.get("Title"),
//                 date: record.get("Date"),
//                 authors: record.get("Authors"),
//                 slug: record.get("Slug"),
//                 tags: record.get("Tags")
//               };
//               _posts.push(_post);
//             }
//           });
//           // fetchNextPage();
//           resolve(_posts);
//         },
//         err => {
//           if (err) {
//             reject(err);
//             return;
//           }
//         }
//       );
//   });
// };

// export const getSlug = id => {
//   return new Promise((resolve, reject) => {
//     base("Pages").find(id, async (err, r) => {
//       if (err) {
//         return err;
//       }
//       let _slug = r.get("Slug");
//       resolve(_slug);
//     });
//   });
// };
