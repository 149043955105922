import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Api from "./../services/api";
import Icon from "react-icons-kit";
import { dribbble, twitter, medium, instagram } from "react-icons-kit/fa";
import Loading from "../components/Loading";
class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      isloaded: false
    };
  }
  componentDidMount() {
    Api.getRecords().then(records => {
      this.setState({ posts: records, isloaded: true });
    });
  }
  render() {
    return (
      <Wrapper>
        {/* <Loading /> */}
        <MainSection>
          <img src="./assets/Wave@2x.png" alt="Hello" />
          <h1 className="title">
            <div>
              <span className="line-bg">Namaste!,</span>
            </div>
            <span className="line-bg">I'm Achuth!</span>
            <br />
            <span className="line-bg">
              A full - time developer,
              <br />
            </span>
            <span className="line-bg">
              wanna be Designer and an {""}
              <a target="_blank" href="https://snipper.netlify.com">
                indie maker
              </a>
            </span>
          </h1>
          <Motto>
            <i>" To be creative is to keep Creating "</i>
            <sub>- Motto</sub>
          </Motto>
          <Details>
            <p>
              Achuth hadnoor is a designer developer and an indie maker. Since
              2018 he launched apps as an{" "}
              <a href="https://producthunt.com/@achuthhadnoor" target="_blank">
                indie maker
              </a>
              .
            </p>
            <h5>Follow my Journey</h5>
            <a href="https://dribbble.com/achuth_hadnoor" target="_blank">
              <Icon icon={dribbble} />
            </a>
            <a href="https://twitter.com/achuth_hadnoor" target="_blank">
              <Icon icon={twitter} />
            </a>
            <a href="https://medium.com/@achuth.hadnoor" target="_blank">
              <Icon icon={medium} />
            </a>
            <a href="https://www.instagram.com/uiuxdx" target="_blank">
              <Icon icon={instagram} />
            </a>
          </Details>
        </MainSection>
        <Section>
          <h2 style={{ color: "goldenrod" }}>Articles</h2>
          {this.state.isloaded ? (
            this.state.posts.map((p, i) => {
              return (
                <Link
                  to={"/blog/" + p.slug}
                  key={`post` + i}
                  className="posts-link"
                >
                  <h4>{p.title}</h4>
                </Link>
              );
            })
          ) : (
            <Loading />
          )}
        </Section>
      </Wrapper>
    );
  }
}
export default Home;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1030px;
  padding: 10px;
  .posts-link {
    color: #aaa;
  }
  .posts-link :hover {
    color: #fff;
  }
`;
const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const MainSection = styled.main`
  max-width: 1030px;
  margin: auto;
  padding: 0px 20px;
`;

const Motto = styled.div`
  i {
    padding: 5px;
    border-left: 2px solid #0000ee;
  }
`;
const Details = styled.div`
  position: relative;
  line-height: 1.7em;
  max-width: 400px;
  svg {
    padding: 0px 10px;
    border-right: 1px solid #aaa;
    font-size: 16px;
  }
  margin-bottom: 100px;
  p a {
    color: goldenrod;
  }
`;
