import React from "react";
import { Link } from "react-router-dom";
import Api from "../services/api";
import styled from "styled-components";
import Loading from "../components/Loading";
class Blog extends React.Component {
  constructor() {
    super();
    this.state = {
      posts: [],
      isloaded: false
    };
  }
  componentDidMount() {
    Api.getRecords()
      .then(records => {
        this.setState({ posts: records, isloaded: true });
      })
      .catch(e => console.log(e));
  }
  render() {
    return (
      <BlogWrapper>
        <h1 style={{ paddingLeft: "10px" }}>Blog</h1>
        {this.state.isloaded ? (
          this.state.posts.map((p, i) => {
            return (
              <Link to={"/blog/" + p.slug} key={`post` + i}>
                <BlogItem
                  title={p.title}
                  slug={p.slug}
                  tags={p.tags}
                  date={p.date}
                  authors={p.authors}
                />
              </Link>
            );
          })
        ) : (
          <Loading />
        )}
      </BlogWrapper>
    );
  }
}
export default Blog;

const BlogItem = props => {
  const { title, tags, date, authors } = props;
  return (
    <Blogitem>
      <>
        <h3>{title}</h3>
        <Details>
          <div className="authors">
            {authors
              ? authors.map((a, i) => <span key={`author` + i}>{a.name}</span>)
              : null}
          </div>
          <div style={{ display: "flex", flex: 1 }}>
            <span className="date">{date.substring(0, 10)}</span>
            <div className="tags">
              {tags
                ? tags.map((t, i) => (
                    <span className="tag" key={`Tag` + i}>
                      #{t}
                    </span>
                  ))
                : null}
            </div>
          </div>
        </Details>
      </>
    </Blogitem>
  );
};

// Compoenents

// Styled components
const Details = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: auto;
  width: 100%;
  h1 {
    padding: 10px 0px;
  }
`;
const Blogitem = styled.div`
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  /* box-shadow: 0 1px 4px rgba(255, 255, 255, 0.1); */
  border: 1px solid #111;
  h3 {
    color: #eee;
  }
  &:hover h3 {
    color: #ffd54f;
  }
  .authors {
    display: flex;
  }
  .authors span {
    padding: 10px;
  }
  .date {
    padding: 10px;
  }
  .tags {
    padding: 10px;
    display: flex;
    color: #222;
  }
  .tag {
    padding: 5px;
    font-size: 0.7em;
    background: #ffd54f;
    /* #ff9066; */
    border-radius: 25px;
  }
`;
