import React from "react";
import Home from "./pages/home";
import Header from "./components/header";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Blog from "./pages/blog";
import Post from "./pages/post";
import Footer from "./components/footer";
import Now from "./pages/now";
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      time: "",
      date: "12",
      day: "Friday",
      year: "2019",
      month: "April"
    };
    this.startTime = this.startTime.bind(this);
  }
  componentDidMount() {
    this.startTime();
  }

  startTime() {
    var { time, date, day, month, year } = this.state;
    const monthsArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var TDate = new Date();
    day = TDate.toString()
      .slice(0, 3)
      .toUpperCase();
    date = `0${TDate.getDate()}`.slice(-2);
    month = monthsArray[TDate.getMonth()].toUpperCase();
    year = TDate.getFullYear();
    time = TDate.toLocaleTimeString();
    this.setState({ date, day, year, month, time });
    setTimeout(this.startTime, 1000);
  }
  render() {
    return (
      <>
        <Router>
          <Header
            time={this.state.time}
            year={this.state.year}
            month={this.state.month}
            date={this.state.date}
            day={this.state.day}
          />
          <Switch>
            <Route path="/about">
              {/* <About /> */}
              <div>
                about <Link to="/">back</Link>
              </div>
            </Route>
            <Route path="/blog" exact>
              <Blog />
            </Route>
            <Route path="/blog/:id" exact>
              <Post />
            </Route>
            <Route path="/now" exact>
              <Now />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>
        </Router>
        <Footer />
        <style>{`
        
        `}</style>
      </>
    );
  }
}
export default App;
