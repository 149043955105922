import { withRouter } from "react-router-dom";
import React from "react";
import styled from "styled-components";
class Post extends React.Component {
  render() {
    return (
      <Wrapper>
        <h1>What i'm doing now</h1>
        <h3>Making digital experiences better </h3>
        <p>
          Teaching self is a messy process . I am on a mission to create
          productivity tools that we use daily more simple and easy to use.
          Teaching self the messy process of designing and developing while
          making them work actually.Teach yourself something is a messy process.
          Not only it's overwhelming to pick the right resource to learn, but
          also it's tough to validate what you've learned. I'm researching this
          problem and thinking more in-depth about it. If you've ever taught
          yourself something from zero to a professional level, hit me up! I'd
          like to know your process and experience, especially the hurdles you
          had to go through.
        </p>
        <h3>Creating Daily UI series</h3>
        <p>Dribble profile</p>
      </Wrapper>
    );
  }
}
export default withRouter(Post);

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 800px;
  width: 100%;
  padding: 5px 10px;
  span {
    padding: 5px;
    text-align: center;
  }
  pre {
    background: #222;
    color: #eee;
    padding: 10px;
    border-radius: 5px;
  }
  p {
    line-height: 1.5;
    padding: 5px 10px;
  }
`;
