import { withRouter } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import Api from "./../services/api";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import moment from "moment";
class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = { post: null };
  }
  componentDidMount() {
    Api.getPost(this.props.match.params.id).then(p => {
      this.setState({ post: p });
    });
  }
  render() {
    const { post } = this.state;
    return (
      <Wrapper>
        <Link to="/blog">
          <span style={{ padding: "10px" }}> Back </span>
        </Link>
        {post ? (
          <article>
            <h1 style={{ paddingTop: "50px" }}>{this.state.post.title}</h1>
            {post.authors.map((a, i) => (
              <span key={`a` + i}>
                <b>Written by :</b> {a.name}
              </span>
            ))}
            <span>
              <b>Date :</b> {post.date.substring(10, 0)}
            </span>
            <Markdown
              className="result"
              source={post.content}
              escapeHtml={false}
            />
          </article>
        ) : (
          ""
        )}
      </Wrapper>
    );
  }
}
export default withRouter(Post);

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  max-width: 800px;
  margin: auto;
  width: 100%;
  font-size: 1em;
  span {
    padding: 5px;
    text-align: center;
  }
  pre {
    background: #222;
    color: #eee;
    padding: 10px;
    border-radius: 5px;
  }
  h1 {
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .result {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
  }
  .result h1 {
    text-align: left;
  }
`;
